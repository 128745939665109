import {
  parseISO
} from "date-fns";

import {
  createResource,
  deleteResource,
  readResource,
  updateResource,
  patchResource
} from "./http";

import {
  findOrThrow
} from "./utility/functional";

import {
  formatDate,
  formatDateWithoutYear,
  formatHours,
  parseDuration
} from "./utility/common";
import {
  deserializeContactSnapshot
} from "@cargotic/api-client";

const ORDER_INDEX_YEAR_LENGTH = 2;
const ORDER_INDEX_USER_LENGTH = 2;
const ORDER_INDEX_ORDER_LENGTH = 4;

const deserializeUser = data => ({
  id: data.id,
  firstName: data.first_name,
  lastName: data.last_name,
  email: data.email,
  phoneNumber: data.phoneNumber,
  role: data.role,
  avatarUrl: data.avatar_url,
  permissions: data.permissions,
  company_created_at: data.company_creation,
  subscription: data.subscription ? {
    ...data.subscription,
    expiresAt: new Date(data.subscription.expiresAt)
  } : undefined
});

const deserializeOrderStatistics = (data, period) => ([{
  id: "Orders",
  period,
  data: data.orders.map(y => ({
    x: period === "hour"
      ? `${formatHours(Date.parse(y.date))}h`
      : (period === undefined
        ? formatDate(new Date(y.date))
        : formatDateWithoutYear(new Date(y.date))),
    y: y.value
  }))
}]);

const deserializeShipmentsStatistics = (data, period) => ([{
  id: "Shipments",
  period,
  data: data.shipments.map(y => ({
    x: period === "hour"
      ? `${formatHours(Date.parse(y.date))}h`
      : (period === undefined
        ? formatDate(new Date(y.date))
        : formatDateWithoutYear(new Date(y.date))),
    date: new Date(y.date),
    y: y.value
  }))
}]);

const deserializeCommissionStatistics = (data, period) => ([{
  id: "Commission",
  period,
  data: data.commission.map(y => ({
    x: period === "hour"
      ? `${formatHours(Date.parse(y.date))}h`
      : (period === undefined
        ? formatDate(new Date(y.date))
        : formatDateWithoutYear(new Date(y.date))),
    date: new Date(y.date),
    y: y.value
  }))
}]);

const deserializeRevenueStatistics = (data, period) => ([{
  id: "Revenue",
  period,
  data: data.revenue.map(y => ({
    x: period === "hour"
      ? `${formatHours(Date.parse(y.date))}h`
      : (period === undefined
        ? formatDate(new Date(y.date))
        : formatDateWithoutYear(new Date(y.date))),
    date: new Date(y.date),
    y: y.value
  }))
}]);

const deserializeCompanyStatistics = data => {
  const arr = [];
  if (data.items.totalShipments !== undefined) {
    arr.push({
      title: "statistics.caption.totalShipments",
      data: data.items.totalShipments,
      unit: null
    });
  }
  if (data.items.totalClients !== undefined) {
    arr.push({
      title: "statistics.caption.totalClients",
      data: data.items.totalClients,
      unit: null
    });
  }
  if (data.goods.totalDuration !== undefined && data.goods.totalDuration !== 0) {
    arr.push({
      title: "statistics.caption.totalDuration",
      data: data.goods.totalDuration / 3600,
      unit: "h"
    });
  }
  if (data.goods.totalDistance !== undefined && data.goods.totalDistance !== 0) {
    arr.push({
      title: "statistics.caption.totalDistance",
      data: data.goods.totalDistance / 1000,
      unit: "km"
    });
  }
  if (data.items.totalRevenue !== undefined) {
    arr.push({
      title: "statistics.caption.totalRevenue",
      data: data.items.totalRevenue,
      unit: "CZK"
    });
  }
  if (data.items.totalCommission !== undefined) {
    arr.push({
      title: "statistics.caption.totalCommission",
      data: data.items.totalCommission,
      alternative: `${!Number.isNaN(data.items.totalCommission / data.items.totalRevenue) ? (data.items.totalCommission / data.items.totalRevenue * 100).toFixed(2) : 0}%`,
      unit: "CZK"
    });
  }
  return arr;
};

const deserializeUserStatistics = data => ({
  totalShipments: data.totalShipments !== undefined
    ? {
      title: "statistics.caption.totalShipments",
      data: data.totalShipments,
      unit: null
    } : undefined,
  totalClients: data.totalClients !== undefined
    ? {
      title: "statistics.caption.totalClients",
      data: data.totalClients,
      unit: null
    } : undefined,
  totalDuration: data.totalDuration
    ? {
      title: "statistics.caption.totalDuration",
      data: data.totalDuration / 3600,
      unit: "h"
    } : undefined,
  totalDistance: data.totalDistance
    ? {
      title: "statistics.caption.totalDistance",
      data: data.totalDistance / 1000,
      unit: "km"
    } : undefined,
  totalRevenue: data.totalRevenue !== undefined
    ? {
      title: "statistics.caption.totalRevenue",
      data: data.totalRevenue,
      unit: "CZK"
    } : undefined,
  totalCommission: data.totalCommission !== undefined
    ? {
      title: "statistics.caption.totalCommission",
      alternative: `${!Number.isNaN(data.totalCommission / data.totalRevenue) ? (data.totalCommission / data.totalRevenue * 100).toFixed(2) : 0}%`,
      data: data.totalCommission,
      unit: "CZK"
    } : undefined
})

const deserializeDriverStatistics = data => [{
  title: "statistics.caption.totalShipments",
  data: data.total_shipments,
  unit: null
},
{
  title: "statistics.caption.totalDistance",
  data: data.total_distance,
  unit: "km"
},
{
  title: "statistics.caption.totalVolume",
  data: data.total_volume_item_m3,
  unit: "m3"
}
];

const deserializeCustomerProfile = data => ({
  firstName: data.first_name,
  lastName: data.last_name,
  phoneNumber: data.phoneNumber,
  profileType: data.profile_type,
  avatarUrl: data.avatar_url
});

const deserializeCompanyProfile = data => ({
  ic: data.ic,
  dic: data.dic,
  url: data.website,
  responsiblePerson: data.responsiblePerson,
  avatarUrl: data.avatarUrl,
  stampSignature: data.stampSignatureFileId ? {
    url: data.stampSignatureUrl,
    id: data.stampSignatureFileId,
    name: data.stampSignatureFileName,
    salt: data.stampSignatureFileSalt
  } : undefined,
  phoneNumber: data.phoneNumber,
  email: data.email,
  mobilePrefix: data.mobilePrefix,
  companyName: data.name,
  address: data.headquarters?.formattedAddress,
  placeId: data.headquarters?.googleId,
  price: data.pricePerKm,
  bankAccountIBAN: data.bankAccountIban,
  bankAccountBBAN: data.bankAccountBban,
  tags: data.tags?.map(({
    label,
    ...rest
  }) => ({
    ...rest,
    value: label
  })),
  statisticsDueTo: data.statisticsDueTo,
  isPersonalEmailHidden: data.isPersonalEmailHidden
});

const deserializeUserWithId = data => ({
  ...deserializeUser(data),
  id: data.id
});

const deserializeUsersWithIds = data => data.map(deserializeUserWithId);

const deserializeContact = data => ({
  id: data.id,
  name: data.name,
  ic: data.ic,
  dic: data.dic,
  email: data.email,
  phoneNumber: data.phoneNumber,
  web: data.web,
  notes: data.notes,
  employees: data.employees,
  type: data.type,
  billingFormattedAddress: data.billingAddr,
  billingPlaceId: data.billingPlaceId,
  deliveryFormattedAddress: data.deliveryAddr,
  deliveryPlaceId: data.deliveryPlaceId,
  isAssigned: data.isAssigned,
  assignedCount: data.assignedCount,
  tags: data.tags,
  insurance: data.insurance ? new Date(data.insurance) : undefined,
  dueDays: data.dueDays,
  createdAt: new Date(data.createdAt),
  creator: data.creator,
  billingContact: data.billingContact,
  electronicalBilling: data.electronicalBilling
});

const deserializeOverviewOrder = data => ({
  indexNumber: data.indexNumber,
  id: data.id,
  isDisabled: data.isDisabled,
  creatorName: data.createdBy,
  description: data.description,
  priceCustomer: data.customerLocalPrice,
  priceCarrier: data.carrierLocalPrice,
  priceCommission: data.commissionNativePrice,
  customerPriceCurrency: data.customerLocalCurrency,
  carrierPriceCurrency: data.carrierLocalCurrency,
  commissionPriceCurrency: data.commissionLocalCurrency,
  customerName: data.customerName,
  carrierName: data.carrierName,
  deliveryFrom: parseISO(data.deliveryAtStart),
  deliveryTo: parseISO(data.deliveryAtStop),
  pickupFrom: parseISO(data.pickupAtStart),
  pickupTo: parseISO(data.pickupAtStop),
  fromPlaceId: data.originPlaceId,
  toPlaceId: data.destPlaceId,
  addressFrom: data.originAddr,
  addressTo: data.destAddr,
  items: data.items,
  dateCreated: parseISO(data.createdAt),
  dateUpdated: data.updatedAt ? parseISO(data.updatedAt) : null,
  hasInvoices: data.hasInvoice
});

const deserializeShipment = data => ({
  name: data.name,
  id: data.id,
  idJourney: data.id_journey,
  origin: data.origin,
  destinationLat: data.destination_lat,
  destinationLong: data.destination_long,
  originLat: data.origin_lat,
  originLong: data.origin_long,
  destination: data.destination,
  duration: data.duration,
  distance: data.distance,
  pickupTime: data.pickup_time,
  deliveryTime: data.estimated_delivery_time,
  estDeliveryTime: data.estimated_delivery_time,
  price: data.price,
  customerId: data.customer_contact_id,
  driverId: data.id_driver,
  vehicleId: data.id_vehicle,
  phoneSender: data.sender_phone,
  emailSender: data.sender_email,
  phoneReceiver: data.receiver_phone,
  emailReceiver: data.receiver_email,
  dateCreated: Date.parse(data.date_created),
  dateUpdated: Date.parse(data.date_updated),
  shipmentDescription: data.description
});

const deserializeCar = data => ({
  ...data,
  id: data.id,
  manufacturer: data.manufacturer,
  model: data.model,
  isAssigned: data.isAssigned,
  licensePlate: data.licensePlate,
  emissionClass: data.emissionClass,
  vin: data.vin,
  avatarUrl: data.avatarUrl
});

const deserializeDriver = data => ({
  id: data.id,
  firstName: data.first_name,
  lastName: data.last_name,
  name: data.name,
  email: data.email,
  avatarUrl: data.avatar_url,
  isAssigned: data.isAssigned
});

const deserializeCustomer = data => ({
  id: data.id,
  name: data.name,
  type: data.type
});

const deserializeDispatcher = data => ({
  id: data.id,
  email: data.email,
  name: data.name,
  phoneNumber: data.phoneNumber,
  createdAt: data.createdAt,
  avatarUrl: data.avatarUrl
});

const deserializeContacts = data => data.map(deserializeContact);

const deserializeOrder = data => ({
  id: data.id,
  departureAddress: data.addr_from,
  departureAddressId: data.place_id_from,
  departureDateTimeFrom: parseISO(data.dt_pickup_from),
  departureDateTimeTo: data.dt_pickup_to ? parseISO(data.dt_pickup_to) : parseISO(data.dt_pickup_from),
  departureDateTimeToFixed: data.dt_pickup_to == null,
  deliveryAddress: data.addr_to,
  deliveryAddressId: data.place_id_to,
  deliveryDateTimeFrom: parseISO(data.dt_delivery_from),
  deliveryDateTimeTo: data.dt_delivery_to ? parseISO(data.dt_delivery_to) : parseISO(data.dt_delivery_from),
  deliveryDateTimeToFixed: data.dt_delivery_to == null,
  carrierId: data.carrier_contact_id,
  customerId: data.customer_contact_id,
  carrierPrice: data.carrier_price_local_value,
  driverContact: data.contact_driver,
  carrierPriceCurrency: data.carrier_price_local_currency,
  customerPrice: data.customer_price_local_value,
  customerPriceCurrency: data.customer_price_local_currency,
  loadingContact: data.contact_pickup,
  unloadingContact: data.contact_delivery,
  note: data.description,
  indexNumber: data.index_number,
  dueDate: data.payment_duedate,
  withDPH: data.with_dph,
  dateCreated: data.date_created,
  dateUpdated: data.date_updated
});

const deserializeOrderItem = data => ({
  id: data.id,
  description: data.description || "",
  quantity: data.amount,
  length: data.length_cm,
  width: data.width_cm,
  height: data.height_cm,
  weight: data.weight_kg,
  stackability: data.stackability === 1,
  unit: data.unit
});

const deserializeOrderIndex = index => ({
  year: parseInt(index.slice(0, ORDER_INDEX_YEAR_LENGTH)),
  user: parseInt(
    index.slice(
      ORDER_INDEX_YEAR_LENGTH,
      ORDER_INDEX_YEAR_LENGTH + ORDER_INDEX_USER_LENGTH
    )
  ),
  order: parseInt(index.slice(-ORDER_INDEX_ORDER_LENGTH))
});

const stringifyOrderIndex = ({
  year,
  user,
  order
}) => ({
  year: year.toString().padStart(ORDER_INDEX_YEAR_LENGTH, "0"),
  user: user.toString().padStart(ORDER_INDEX_USER_LENGTH, "0"),
  order: order.toString().padStart(ORDER_INDEX_ORDER_LENGTH, "0")
});

const shipmentParseDates = ({
  journey: {
    waypoints,
    ...restJourney
  },
  paidAt,
  ...rest
}) => {
  const updatedPoints = waypoints.map(
    point => ({
      ...point,
      arriveAtFrom: new Date(point.arriveAtFrom),
      arriveAtTo: point.arriveAtTo ? new Date(point.arriveAtTo) : undefined
    })
  );

  return {
    ...rest,
    journey: {
      ...restJourney,
      waypoints: updatedPoints
    },
    paidAt: paidAt ? new Date(paidAt) : undefined
  };
};

const createOrder = (
  from,
  to,
  departureDateTimeFrom,
  departureDateTimeTo,
  deliveryDateTimeFrom,
  deliveryDateTimeTo,
  carrierId,
  customerId,
  carrierPrice,
  carrierPriceCurrency,
  customerPrice,
  customerPriceCurrency,
  commission,
  commissionCurrency,
  loadingContact,
  unloadingContact,
  dueDate,
  note,
  distance,
  duration,
  placeIdFrom,
  placeIdTo,
  index,
  name,
  withDPH,
  driverContact
) => createResource("orders", {
  carrierPriceCurrency,
  customerPriceCurrency,
  addr_from: from,
  addr_to: to,
  dt_pickup_from: departureDateTimeFrom,
  dt_pickup_to: departureDateTimeTo,
  dt_delivery_from: deliveryDateTimeFrom,
  dt_delivery_to: deliveryDateTimeTo,
  carrier_contact_id: carrierId,
  customer_contact_id: customerId,
  price_carrier: carrierPrice,
  price_customer: customerPrice,
  contact_pickup: loadingContact,
  contact_delivery: unloadingContact,
  description: note,
  place_id_from: placeIdFrom,
  place_id_to: placeIdTo,
  index_number: index,
  distance,
  duration,
  payment_duedate: dueDate,
  with_dph: withDPH,
  contact_driver: driverContact,
  commissionCurrency,
  price_commission: commission

}).then(({
  data
}) => data.id);

const createShipment = ({
  name,
  customerId,
  driverId,
  vehicleId,
  phoneSender,
  emailSender,
  phoneReceiver,
  emailReceiver,
  origin,
  destination,
  originLat,
  originLng,
  originPlaceId,
  destinationLat,
  destinationLng,
  destinationPlaceId,
  pickupTime,
  deliveryTime,
  duration,
  distance,
  price,
  shipmentDescription
}) => createResource("shipments", {
  name,
  customer_contact_id: customerId,
  id_driver: driverId,
  id_vehicle: vehicleId,
  sender_phone: phoneSender,
  sender_email: emailSender,
  receiver_phone: phoneReceiver,
  receiver_email: emailReceiver,
  origin,
  destination,
  origin_lat: originLat,
  origin_lng: originLng,
  origin_place_id: originPlaceId,
  destination_lat: destinationLat,
  destination_lng: destinationLng,
  destination_place_id: destinationPlaceId,
  pickup_time: pickupTime,
  estimated_delivery_time: deliveryTime,
  duration,
  distance,
  price,
  description: shipmentDescription
}).then(({
  data
}) => data);

const createShipmentItem = (id, amount, description, length, height, width, weight) => {
  createResource(`shipments/${id}/items`, {
    amount,
    width_cm: width,
    height_cm: height,
    length_cm: length,
    weight_kg: weight
  });
};

const createRating = (contactId, type, text) => {
  return createResource(`rating`, {
    contactId,
    type,
    text
  });
};

const getRatings = (contactId) => {
  return readResource(`rating/${contactId}`);
};

const deleteRating = (id) => {
  return deleteResource('rating/delete', {
    id: id
  });
};

const createOrderItems = (orderId, items) => createResource(`orders/${orderId}/items`, items.map(({
  quantity,
  width,
  height,
  length,
  weight,
  description,
  stackability,
  unit
}) => ({
  amount: quantity,
  width_cm: width,
  height_cm: height,
  length_cm: length,
  weight_kg: weight,
  description,
  stackability,
  unit
})));

const createOrdersQuery = args => createResource("orders/query", args).then(res => ({
  count: res.data.count,
  orders: res.data.orders.map(deserializeOverviewOrder)
}));

const createUserMatchQuery = ({ search }) => createResource("users/match", { match: { search } }).then(({ data }) => data);

// const createShipmentsQuery = (args) => createResource("newShipment", args).then(res => ({ count: res.data.count, shipments: res.data.deserializedShipments }));
const createShipmentsQuery = (args) => createResource("new-shipments/query", args).then(res => ({
  count: res.data.count,
  shipments: res.data.shipments.map(shipment => shipmentParseDates(shipment))
}));

const createShipmentBoardQuery = (args) => createResource("new-shipments/board", args).then(({ data }) => ({
  count: data.count,
  matches: {
    QUEUE: data.QUEUE ? {
      count: data.QUEUE.count,
      totalWarnings: data.QUEUE.totalWarnings,
      totalErrors: data.QUEUE.totalErrors,
      shipments: data.QUEUE.shipments.map(shipment => shipmentParseDates(shipment))
    } : {},
    READY: data.READY ? {
      count: data.READY.count,
      totalWarnings: data.READY.totalWarnings,
      totalErrors: data.READY.totalErrors,
      shipments: data.READY.shipments.map(shipment => shipmentParseDates(shipment))
    } : {},
    IN_PROGRESS: data.IN_PROGRESS ? {
      count: data.IN_PROGRESS.count,
      totalWarnings: data.IN_PROGRESS.totalWarnings,
      totalErrors: data.IN_PROGRESS.totalErrors,
      shipments: data.IN_PROGRESS.shipments.map(shipment => shipmentParseDates(shipment))
    } : {},
    DONE: data.DONE ? {
      count: data.DONE.count,
      totalWarnings: data.DONE.totalWarnings,
      totalErrors: data.DONE.totalErrors,
      shipments: data.DONE.shipments.map(shipment => shipmentParseDates(shipment))
    } : {}
  }
}));

const updateShipmentState = (shipmentId, newState) => updateResource(`new-shipments/${shipmentId}/state`, { state: newState })
  .then(({ data }) => data);

const createVehiclesQuery = args => createResource("vehicles/query", args).then(res => ({
  count: res.data.count,
  vehicles: res.data.vehicles.map(deserializeCar)
}));

const readVehicles = () => readResource("vehicles").then(vehicles => vehicles.data.map(deserializeCar));

const readDrivers = () => readResource("users/drivers").then(drivers => drivers.data.map(deserializeDriver));

const createDriversQuery = args => createResource("users/drivers/query", args).then(res => ({
  count: res.data.count,
  drivers: res.data.users.map(deserializeDriver)
}));

const readCustomers = () => readResource("contacts").then(customers => customers.data.map(deserializeCustomer).filter(({
  type
}) => type === "customer"));

const readCarriers = () => readResource("contacts").then(carriers => carriers.data.map(deserializeCustomer).filter(({
  type
}) => type === "carrier"));

const createDispatchersQuery = args => createResource("users/dispatchers/query", args).then(res => ({
  count: res.data.count,
  dispatchers: res.data.users.map(deserializeDispatcher)
}));

const deleteOrder = id => deleteResource(`orders/${id}`);

const deleteShipment = id => deleteResource(`new-shipments/${id}`);

const deleteVehicle = id => deleteResource(`vehicles/${id}`);

const deleteShipmentItem = (idShipment, idItem) => deleteResource(`shipments/${idShipment}/items/${idItem}`);

const deleteUser = id => deleteResource(`users/delete/${id}`);

const readShipment = id => readResource(`shipments/${id}`).then(shipment => shipment.data.map(deserializeShipment));

const readShipments = () => readResource("shipments").then(shipments => shipments.data.map(deserializeShipment));

// const createSignIn = (token, remember) => createResource("auth/signin", {
//   token,
//   remember
// }).then(({
//   data
// }) => deserializeUser(data));

const createSignIn = (email, password, remember) => createResource("auth/signin", {
  email,
  password,
  remember
}).then(({
  data
}) => deserializeUser(data));

const createResetPassword = (email) => createResource("auth/reset-password", {
  email
}).then(({
  data
}) => data);

const setPassword = (password, token) => createResource("auth/set-password", {
  password,
  token
}).then(({
  data
}) => data);

const createResendVerificationMail = (email) => createResource("auth/resend-verification", {
  email
});

const createSignUp = ({
  firstName,
  lastName,
  email,
  companyName,
  ic,
  dic,
  tariffType,
  tariffCountry,
  countUsers,
  isSubscriber
}) => createResource("auth/signup", {
  firstName,
  lastName,
  email,
  companyName,
  ic,
  dic,
  tariffType,
  tariffCountry,
  users: countUsers,
  isSubscriber
});

const createDispatcher = (firstName, lastName, email, phoneNumber) => createResource("users/dispatchers", {
  first_name: firstName,
  last_name: lastName,
  email,
  phoneNumber
}).then(({
  data
}) => data.idDispatcher);

const readDispatchers = () => readResource("users/dispatchers").then(({
  data
}) => deserializeUsersWithIds(data));

const readDriverStatistics = (id, start, end) => readResource(`statistics/driver/${id}`, { fromDate: start, toDate: end }).then(({
  data
}) => deserializeDriverStatistics(data));

const readMe = () => readResource("users/getSession").then(({
  data
}) => deserializeUser(data));

const updateDispatcher = (id, firstName, lastName) => updateResource(`users/dispatchers/${id}`, {
  first_name: firstName,
  last_name: lastName
});

const deleteDispatcher = id => deleteResource(`users/dispatchers/${id}`);

const createDriver = (firstName, lastName, email, phoneNumber) => createResource("users/drivers", {
  first_name: firstName,
  last_name: lastName,
  email,
  phoneNumber
}).then(response => response.data.idDriver);

const updateDriver = (id, email, firstName, lastName) => updateResource(`users/drivers/${id}`, {
  email,
  first_name: firstName,
  last_name: lastName
});

const deleteDriver = id => updateResource(`users/drivers/${id}`, {
  deleted: 1
});

const createPlace = (alias, address) => createResource("places", {
  ...address,
  alias
});

const createContact = ({
  companyName,
  ic,
  dic,
  email,
  phoneNumber,
  website,
  notes,
  employees,
  type,
  billingPlace,
  tags,
  insuranceExpiresAt,
  paymentDueDays,
  mailingPlace,
  billingContact,
  isBilledOnline,
  isPrivate
}) => createResource("contacts", {
  companyName,
  ic,
  dic,
  email,
  phoneNumber,
  website,
  notes,
  employees,
  type,
  insuranceExpiresAt,
  paymentDueDays,
  billingPlace,
  mailingPlace,
  tags,
  isBilledOnline,
  billingContact,
  isPrivate
}).then(({
  data: {
    id
  }
}) => id);

const readCustomerProfile = () => readResource("customer-profile").then(({
  data
}) => deserializeCustomerProfile(data));

const createSignOut = () => createResource("auth/signout").then(({
  data
}) => data);

const updateRole = (args) => updateResource("users/role", args);

const deleteContact = id => deleteResource(`contacts/${id}`);

const deletePlace = id => deleteResource(`places/${id}`);

const deleteMe = () => deleteResource("users/deleteAccount");

const deleteCompany = () => deleteResource("company-profile/delete");

const readContacts = () => readResource("contacts").then(({
  data
}) => deserializeContacts(data));
const createContactsQuery = args => createResource("contacts/query", args).then(res => ({
  count: res.data.total,
  contacts: res.data.matches.map(deserializeContactSnapshot)
}));

const createPlacesQuery = args => createResource("places/query", args).then(res => ({
  count: res.data.count,
  places: res.data.places.map(deserializePlace)
}));

const downloadPDFOrder = id => readResource(`pdf/order/${id}`).then(({
  data
}) => console.log(data));

const readUserHighlights = (from, to) => readResource("statistics/me/highlights", { fromDate: from, toDate: to }).then(({
  data
}) => data);

const readCompanyHighlights = (from, to) => readResource("statistics/company/highlights", { fromDate: from, toDate: to }).then(({
  data
}) => data);

const readIcoInfo = (ico) => readResource("ico", { ico: ico}).then(({
  data
}) => data);

const readNextBulkShipmentIndexNumber = () => readResource("bulk-shipments/next-index-number").then(({
  data
}) => data);


const readNews = () => readResource("blog").then(({
  data: {
    news
  }
}) => news);

// const readPersonalForwarderStatistics = (from, to) => readResource(`statistics/personal?from_date=${from}&to_date=${to}`).then(({
//   data
// }) => deserializePersonalForwarderStatistics(data));

const readMyStatistics = (filter, from, to) => createResource("statistics/me", { fromDate: from, toDate: to, filter }).then(({
  data
}) => deserializeUserStatistics(data));

const readUserKpiStatistics = (filter, userId, from, to) => createResource(`statistics/user/${userId}/kpi`, {
  fromDate: from,
  toDate: to,
  filter
}).then(({
  data
}) => deserializeUserStatistics(data));

const readUserRevenueStatistics = (filter, userId, from, to, period) => createResource(`statistics/user/${userId}/revenue`, {
  fromDate: from,
  toDate: to,
  period,
  filter
})
  .then(({
    data
  }) => deserializeRevenueStatistics(data, period));

const readUserCommissionStatistics = (filter, userId, from, to, period) => createResource(`statistics/user/${userId}/commission`, {
  fromDate: from,
  toDate: to,
  period,
  filter
}).then(({
  data
}) => deserializeCommissionStatistics(data, period));

const readUserShipmentsStatistics = (filter, userId, from, to, period) => createResource(`statistics/user/${userId}/shipments`, {
  fromDate: from,
  toDate: to,
  period,
  filter
}).then(({
  data
}) => deserializeShipmentsStatistics(data, period));

const readLastOrderIndex = () => readResource("orders/lastOrderNumber")
  .then(({
    data: {
      lastNumber
    }
  }) => deserializeOrderIndex(lastNumber));

const readUserIndexNumber = () => readResource("users/getUserIndexNumber").then(({
  data
}) => data.indexNumber);

const readNextOrderIndex = async () => {
  const now = new Date();
  const year = parseInt(
    now.getFullYear().toString().slice(-ORDER_INDEX_YEAR_LENGTH)
  );

  const user = await readUserIndexNumber();

  try {
    const {
      year: lastYear,
      order: lastOrder
    } = await readLastOrderIndex();
    const order = year === lastYear ? lastOrder + 1 : 1;

    return {
      year,
      user,
      order
    };
  } catch (err) {
    if (err.response && err.response.status !== 400) {
      throw err;
    }

    return {
      year,
      user,
      order: 1
    };
  }
};

const readCompanyStatistics = (filter, from, to) => createResource("statistics/company", { fromDate: from, toDate: to, filter }).then(({
  data
}) => deserializeCompanyStatistics(data));

const readCompanyRevenueStatistics = (filter, from, to, period) => createResource("statistics/company/revenue", { fromDate: from, toDate: to, period, filter }).then(({
  data
}) => deserializeRevenueStatistics(data, period));

const readMyRevenueStatistics = (filter, from, to, period) => createResource("statistics/me/revenue", { fromDate: from, toDate: to, period, filter }).then(({
  data
}) => deserializeRevenueStatistics(data, period));

const readMyCommissionStatistics = (filter, from, to, period) => createResource("statistics/me/commission", { fromDate: from, toDate: to, period, filter }).then(({
  data
}) => deserializeCommissionStatistics(data, period));

const readCompanyCommissionStatistics = (filter, from, to, period) => createResource("statistics/company/commission", { fromDate: from, toDate: to, period, filter }).then(({
  data
}) => deserializeCommissionStatistics(data, period));

const readCompanyShipmentStatistics = (filter, from, to, period) => createResource("statistics/company/shipments", { fromDate: from, toDate: to, period, filter }).then(({
  data
}) => deserializeShipmentsStatistics(data, period));

const readMyShipmentStatistics = (filter, from, to, period) => createResource("statistics/me/shipments", { fromDate: from, toDate: to, period, filter }).then(({
  data
}) => deserializeShipmentsStatistics(data, period));

const updateCustomerProfile = (firstName, lastName, phoneNumber, avatar) => updateResource("customer-profile", {
  first_name: firstName,
  last_name: lastName,
  phoneNumber,
  avatar
});

const updateCustomerProfileAvatar = async (file) => {
  const formData = new FormData();
  formData.append("avatar", file);

  const {
    data: {
      avatar_url: avatarUrl
    }
  } = await updateResource("customer-profile", formData);

  return avatarUrl;
};

const updateCompanyProfileAvatar = async (file) => {
  const formData = new FormData();
  formData.append("avatar", file);

  const {
    data: {
      avatarUrl
    }
  } = await updateResource("company-profile", formData);

  return avatarUrl;
};

const updateVehicleAvatar = async (id, file) => {
  const formData = new FormData();
  formData.append("avatar", file);

  const {
    data: {
      avatarUrl
    }
  } = await updateResource(`vehicles/${id}`, formData);

  return avatarUrl;
};

const updateCompanyProfile = ({
  ic,
  dic,
  url,
  companyName,
  phoneNumber,
  email,
  address,
  placeId,
  mobilePrefix,
  responsiblePerson,
  price,
  tags,
  statisticsDueTo,
  isPersonalEmailHidden
}) => updateResource("company-profile", {
  ic,
  dic,
  website: url,
  name: companyName,
  email,
  phoneNumber,
  mobilePrefix,
  headquarters: {
    formattedAddress: address,
    googleId: placeId
  },
  responsiblePerson,
  pricePerKm: price,
  tags,
  statisticsDueTo,
  isPersonalEmailHidden
});

const readUserTerms = () => readResource("terms/user")
  .then(({ data }) => data);

const readCompanyTerms = () => readResource("terms/company")
  .then(({ data }) => data);

const createTerms = (terms, isCompany) => createResource("terms", {
  terms,
  isCompany
}).then(({ data }) => data);

const updateTerms = (termsId, terms) => updateResource(`terms/${termsId}`, {
  terms
});

const deleteTerms = (termsId) => deleteResource(`terms/${termsId}`);

const updatePlace = (alias, address, placeId) => updateResource(`places/${placeId}`, {
  ...address,
  alias
});

const updateContact = ({
  id,
  companyName,
  ic,
  dic,
  email,
  phoneNumber,
  website,
  notes,
  billingPlace,
  mailingPlace,
  type,
  tags,
  insuranceExpiresAt,
  paymentDueDays,
  isBilledOnline,
  isPrivate,
  billingContact
}) => updateResource(`contacts/${id}`, {
  companyName,
  ic,
  dic,
  email,
  phoneNumber,
  website,
  notes,
  billingPlace,
  mailingPlace,
  type,
  tags,
  insuranceExpiresAt,
  paymentDueDays,
  isBilledOnline,
  billingContact,
  isPrivate,
  tags
});

const deleteEmployee = (
  contactId,
  id
) => deleteResource(`contacts/${contactId}/employees/${id}`);

const updateEmployee = (
  contactId,
  id,
  name,
  email,
  phoneNumber
) => updateResource(`contacts/${contactId}/employees/${id}`, {
  name,
  email,
  phoneNumber
});

const createEmployee = (
  contactId,
  name,
  email,
  phoneNumber
) => createResource(`contacts/${contactId}/employees`, {
  name,
  email,
  phoneNumber
})

/*
const updateEmployee = (
  id,
  name,
  email,
  phoneNumber
) => updateResource(`contacts/employee/${id}`,{
  name,
  email,
  phoneNumber
})*/

const createCompanyProfile = (ic, dic, url, companyName, email, address, placeId) => createResource("company-profile", {
  ic,
  dic,
  url,
  company_name: companyName,
  email,
  address,
  place_id: placeId,
  tags: []
});

const createVehicle = (
  vendor,
  name,
  spz,
  vin,
  fuel,
  height,
  width,
  length,
  maxLoad,
  date
) => createResource("vehicles", {
  vendor,
  name,
  spz,
  vin,
  fuel,
  cargoAreaHeight: height,
  cargoAreaWidth: width,
  cargoAreaLength: length,
  cargoAreaLoad: maxLoad,
  manufacturedAt: date
}).then(({
  data
}) => data);

const inviteUser = ({
  firstName,
  lastName,
  email,
  role,
  phone
}) => createResource("users/invite", {
  firstName,
  lastName,
  email,
  role,
  phoneNumber: phone
});

const reinviteUser = ({
  email,
}) => createResource("auth/reinvite", {
  email
});

const deserializeInvoices = data => ({
  id: data.id,
  datePaid: data.date_paid,
  isPaid: data.paid,
  price: data.price,
  currency: data.price_currency,
  createdDate: data.date_created
});

const createShipmentInvoice = (shipmentId) => createResource(`invoices/generateShipmentInvoice/${shipmentId}`).then(({
  data
}) => data);

const deserializePeer = data => ({
  id: data.id,
  name: `${data.first_name} ${data.last_name}`,
  isOnline: data.isOnline,
  unreadMessages: data.unreadMessages,
  role: data.role,
  lastMessage: data.last_message,
  avatarUrl: data.avatar_url
});

const deserializeTrack = data => ({
  id: data.id,
  shipmentId: data.shipmentId,
  lat: Number(data.gpsLatitude),
  lng: Number(data.gpsLongitude),
  speed: data.speed,
  createdAt: parseISO(data.createdAt)
});

const deserializeTariff = (data) => ({
  country: data.tariff_country,
  currency: data.price_currency,
  customerId: data.id_customer,
  startTime: data.effective_starttime,
  endTime: data.effective_endtime,
  role: data.tariff_type,
  billingPeriod: data.plan,
  tariffType: data.tariff_code,
  durationDays: data.days,
  price: data.price
});

const readCompanyProfile = (language) => readResource(`company-profile?language=${language}`).then(({
  data
}) => deserializeCompanyProfile(data));

const readCompanyPhonePrefix = () => readResource(`company-profile/phone-prefix`).then(({
  data
}) => data);

const readExchangeRate = (sourceCurrency, targetCurrency) => readResource("exchange", { sourceCurrency, targetCurrency }).then(({ data }) => data);

const createPlaceInput = ({
  input,
  isFound
}) => createResource("analytics/shipment/place-input", {
  input,
  isFound
});

const readOrder = id => readResource(`orders/${id}`)
  .then(({
    data: [first]
  }) => deserializeOrder(first));

const readOrderByIndexNumber = indexNumberOrder => readResource(`orders/orderByIndexNumber/${indexNumberOrder}`)
  .then(({
    data
  }) => deserializeOrder(data));

const readOrderItems = orderId => readResource(`orders/${orderId}/items`)
  .then(({
    data
  }) => data.map(deserializeOrderItem));

const readOrderAudits = orderId => readResource(`orders/${orderId}/audits`).then(({
  data
}) => data);

const readShipmentItems = shipmentId => readResource(`shipments/${shipmentId}/items`)
  .then(({
    data
  }) => data.map(deserializeOrderItem));

const readContact = id => readContacts()
  .then(contacts => findOrThrow(contacts, ({
    id: other
  }) => other === id));

const readInvoices = () => readResource("invoices").then(({
  data
}) => data.map(deserializeInvoices));

const readTracks = () => readResource("track").then(response => response.data.tracks.map(deserializeTrack));


const readTariffs = language => readResource(`tariffs?language=${language}`).then(({
  data
}) => data.tariffs);

const readPublicTariffs = language => readResource(`tariffs/public?language=${language}`).then(({
  data
}) => data.tariffs);

const updateOrder = (
  id,
  from,
  to,
  departureDateTimeFrom,
  departureDateTimeTo,
  deliveryDateTimeFrom,
  deliveryDateTimeTo,
  carrierId,
  customerId,
  carrierPrice,
  carrierPriceCurrency,
  customerPrice,
  customerPriceCurrency,
  commission,
  commissionCurrency,
  loadingContact,
  unloadingContact,
  dueDate,
  note,
  distance,
  duration,
  placeIdFrom,
  placeIdTo,
  index,
  name,
  withDPH,
  conditions,
  driverContact
) => updateResource(`orders/${id}`, {
  carrierPriceCurrency,
  customerPriceCurrency,
  commissionCurrency,
  addr_from: from,
  addr_to: to,
  dt_pickup_from: departureDateTimeFrom,
  dt_pickup_to: departureDateTimeTo,
  dt_delivery_from: deliveryDateTimeFrom,
  dt_delivery_to: deliveryDateTimeTo,
  carrier_contact_id: carrierId,
  customer_contact_id: customerId,
  price_carrier: carrierPrice,
  price_customer: customerPrice,
  price_commission: commission,
  contact_pickup: loadingContact,
  contact_delivery: unloadingContact,
  description: note,
  place_id_from: placeIdFrom,
  place_id_to: placeIdTo,
  distance,
  duration,
  payment_duedate: dueDate,
  with_dph: withDPH,
  conditions,
  index_number: index,
  contact_driver: driverContact
}).then(() => undefined);

const updateShipment = ({
  shipmentName,
  customerId,
  driverId,
  vehicleId,
  phoneSender,
  emailSender,
  phoneReceiver,
  emailReceiver,
  origin,
  destination,
  originLat,
  originLng,
  originPlaceId,
  destinationLat,
  destinationLng,
  destinationPlaceId,
  pickupTime,
  deliveryTime,
  duration,
  distance,
  price,
  id,
  idJourney,
  shipmentDescription
}) => updateResource(`shipments/${id}`, {
  name: shipmentName,
  customer_contact_id: customerId,
  id_driver: driverId,
  id_vehicle: vehicleId,
  sender_phone: phoneSender,
  sender_email: emailSender,
  receiver_phone: phoneReceiver,
  receiver_email: emailReceiver,
  origin,
  destination,
  origin_lat: originLat,
  origin_lng: originLng,
  origin_place_id: originPlaceId,
  destination_lat: destinationLat,
  destination_lng: destinationLng,
  destination_place_id: destinationPlaceId,
  pickup_time: pickupTime,
  estimated_delivery_time: deliveryTime,
  duration,
  distance,
  price,
  id_journey: idJourney,
  description: shipmentDescription
});

const updateShipmentItem = (idShipment, idItem, amount, width, height, length, weight) => updateResource(`shipments/${idShipment}/items/${idItem}`, {
  amount,
  width_cm: width,
  height_cm: height,
  length_cm: length,
  weight_kg: weight
});

const updateVehicle = (id, vendor, name, spz, vin, fuel, height, width, length, maxLoad, date) => updateResource(`vehicles/${id}`, {
  vendor,
  name,
  spz,
  vin,
  fuel,
  cargoAreaHeight: height,
  cargoAreaWidth: width,
  cargoAreaLength: length,
  cargoAreaLoad: maxLoad,
  manufacturedAt: date
}).then(() => undefined);

const createShipmentsExport = (ids, language) => createResource("new-shipments/exports", {
  ids,
  language
})
  .then(({
    data: {
      url
    }
  }) => url);

const createMail = (content) => createResource("email/feedback", {
  content
});

const readCompanyInfo = (ic) => readResource(`contacts/info/${ic}`);

const readAvailableTags = (t, language) => readResource(`contacts/tags?language=${language}`).then(({
  data: tags
}) => tags.map(({
  id,
  type,
  geopoliticalType,
  label: value
}) => ({
  id,
  value,
  type,
  geopoliticalType,
  localizedType: t(`contact.tag.${type.toLowerCase()}`),
  localizedGeopoliticalType: geopoliticalType ? t(`contact.tag.${geopoliticalType.toLowerCase()}`) : undefined
})));

const updateSubscription = (tariffId) =>
  updateResource("subscription", {
    tariffId
  });

const readCompanyBanking = () => (
  readResource("company-profile/banking").then(({ data }) => data.map((item) => ({
    id: item.bankAccountId,
    bban: item.bban,
    iban: item.iban,
    currency: item.currency,
    isDeleted: item.isDeleted,
    creatorId: item.creatorId,
    companyId: item.companyId
  })))
);

const createContactShares = (contactId, data) => (createResource(`contacts/${contactId}/shares`, data).then(({ data }) => data));

const createCompanyBanking = ({ currency, iban, bban }) => (
  createResource("company-profile/banking", { currency, iban, bban }).then(({ data }) => data)
);

const deleteCompanyBanking = (bankAccountId) => (
  deleteResource(`company-profile/banking/${bankAccountId}`)
);

const updateCompanyBanking = (bankAccountId, { currency, iban, bban }) =>
  updateResource(`company-profile/banking/${bankAccountId}`, { bban, iban, currency }).then(({ data }) => data);

const updateBankAccount = (iban, bban) => (
  updateResource("company-profile/banking", {
    bankAccountIban: iban,
    bankAccountBban: bban
  })
    .then(({
      data
    }) => deserializeCompanyProfile(data))
);

const createStampSignature = async (stampSignatureFile) => {
  const formData = new FormData();
  formData.append("stampSignature", stampSignatureFile);

  const result = await createResource("company-profile/stamp-signature", formData).then(({ data: { id, createdAt, url, name } }) => ({
    id,
    createdAt: new Date(createdAt),
    url,
    name
  }));
  return result;
}

const deleteStampSignature = () => deleteResource("company-profile/stamp-signature");

const deserializePlace = (place) => ({
  ...place,
  description: place.alias,
});

const suggestPlacesByAlias = (alias) => createResource("places/suggest", {
  alias
}).then(({
  data
}) => data.places.map(deserializePlace));

const findUserById = (userId) => readResource(`users/find/${userId}`)
  .then(({ data }) => ({
    id: data.id,
    firstName: data.first_name,
    lastName: data.last_name,
    email: data.email,
    role: data.role,
    phoneNumber: data.phoneNumber
  }));

const patchContactOwner = (contactId, ownerId) => patchResource(`contacts/${contactId}/owner`, { ownerId })
  .then(({ data }) => ({
    ...data,
    editedAt: new Date(data.editedAt),
    createdAt: new Date(data.createdAt)
  }));

export {
  deserializeOrderIndex,
  createContact,
  createPlace,
  createDispatcher,
  createDriver,
  createShipment,
  createMail,
  createShipmentItem,
  createOrder,
  createOrderItems,
  createShipmentsExport,
  createSignIn,
  createSignUp,
  createResendVerificationMail,
  createResetPassword,
  readNextOrderIndex,
  createCompanyProfile,
  createVehicle,
  createSignOut,
  deleteContact,
  deletePlace,
  deleteDispatcher,
  deleteOrder,
  deleteShipment,
  deleteDriver,
  deleteMe,
  deleteVehicle,
  deleteShipmentItem,
  downloadPDFOrder,
  readContacts,
  createContactsQuery,
  createPlacesQuery,
  readContact,
  readCarriers,
  readCompanyProfile,
  readCustomerProfile,
  readUserCommissionStatistics,
  readCompanyCommissionStatistics,
  readInvoices,
  readDispatchers,
  createDispatchersQuery,
  readCompanyStatistics,
  createOrdersQuery,
  createShipmentsQuery,
  createShipmentBoardQuery,
  readShipment,
  readShipments,
  readUserShipmentsStatistics,
  readMyCommissionStatistics,
  readMyRevenueStatistics,
  readMyShipmentStatistics,
  readCompanyShipmentStatistics,
  readShipmentItems,
  readUserKpiStatistics,
  readUserRevenueStatistics,
  readCompanyRevenueStatistics,
  readLastOrderIndex,
  readUserIndexNumber,
  readVehicles,
  createVehiclesQuery,
  createDriversQuery,
  readDrivers,
  readDriverStatistics,
  readPublicTariffs,
  readCustomers,
  readMe,
  readMyStatistics,
  readNews,
  updateDispatcher,
  updateDriver,
  updateCustomerProfile,
  updateCompanyProfile,
  updateContact,
  updatePlace,
  readOrder,
  readOrderByIndexNumber,
  readOrderItems,
  readOrderAudits,
  updateOrder,
  updateShipment,
  updateShipmentState,
  updateShipmentItem,
  updateVehicle,
  updateVehicleAvatar,
  updateCustomerProfileAvatar,
  updateCompanyProfileAvatar,
  readTracks,
  readCompanyInfo,
  readTariffs,
  stringifyOrderIndex,
  createShipmentInvoice,
  updateBankAccount,
  inviteUser,
  reinviteUser,
  deleteUser,
  deleteCompany,
  updateRole,
  readUserHighlights,
  readCompanyHighlights,
  updateSubscription,
  suggestPlacesByAlias,
  readAvailableTags,
  updateEmployee,
  createEmployee,
  deleteEmployee,
  readExchangeRate,
  createPlaceInput,
  findUserById,
  createContactShares,

  readCompanyBanking,
  createCompanyBanking,
  deleteCompanyBanking,
  updateCompanyBanking,

  readCompanyTerms,
  readUserTerms,
  createTerms,
  updateTerms,
  deleteTerms,

  createStampSignature,
  deleteStampSignature,

  patchContactOwner,
  createUserMatchQuery,
  readIcoInfo,
  createRating,
  deleteRating,
  getRatings,
  readNextBulkShipmentIndexNumber,
  readCompanyPhonePrefix,
  setPassword
};
