import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import { Permission } from "@cargotic/model";

import {
  createSignIn,
  createSignUp,
  createSignOut,
  readMe,
  createResetPassword,
  createResendVerificationMail
} from "./resource";

class WebappAccessError extends Error {
  constructor() {
    super();

    this.code = "auth.error.platformPermission";
  }
}

class AuthError extends Error {

}

class FirebaseSessionExpiredError extends AuthError {

}

class SessionExpiredError extends AuthError {

}

const AuthPolicy = {
  ANY: "ANY",
  ENFORCE: "ENFORCE",
  FORBID: "FORBID"
};

const restoreFirebaseSession = (timeout = 2000) => new Promise((resolve) => {
  const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
    const scheduler = setTimeout(() => {
      unsubscribe();
      resolve(null);
    }, timeout);

    if (user !== null) {
      clearTimeout(scheduler);
      resolve(user);
    }
  });
});

const resetPassword = (email) => createResetPassword(email);

const resendVerificationMail = (email) => createResendVerificationMail(email);

const restoreSession = async () => {
  let user = null;

  try {
    user = await readMe();
  } catch (error) {
    const { response } = error;

    if (!response || response.status !== 400) {
      throw error;
    }

    throw new SessionExpiredError();
  }

  const firebaseUser = await restoreFirebaseSession();

  if (!firebaseUser) {
    await createSignOut();
    throw new FirebaseSessionExpiredError();
  }

  return {
    ...user,
    emailVerified: firebaseUser.emailVerified
  };
};

const reloadSession = async () => {
  await firebase.auth().currentUser.reload();

  const { emailVerified } = firebase.auth().currentUser;
  const user = await readMe();

  return {
    ...user,
    emailVerified: true
  };
};

// const signIn = async (email, password, remember) => {
//   const { user: firebaseUser } = await firebase.auth()
//     .signInWithEmailAndPassword(email, password);
//   const token = await firebaseUser.getIdToken();
//   const user = await createSignIn(token, remember);
//   // console.log(Permission.platform.webapp.access);
//   if (!user.permissions.includes("platform.webapp.access")) {
//     await signOut();
//     throw new WebappAccessError();
//   }
//
//   return {
//     ...user,
//     emailVerified: firebaseUser.emailVerified
//   };
// };

const signIn = async (email, password, remember) => {
  const user = await createSignIn(email, password, remember);
  // console.log(Permission.platform.webapp.access);
  if (!user.permissions.includes("platform.webapp.access")) {
    await signOut();
    throw new WebappAccessError();
  }

  return {
    ...user,
    emailVerified: user.emailVerified
  };
};

// const signOut = () => firebase
//   .auth()
//   .signOut()
//   .then(() => createSignOut());

const signOut = () => createSignOut();

const signUp = ({ firstName, lastName, email, password, companyName, ic, dic, tariffType, countUsers, isSubscriber }) => firebase
  .auth()
  .createUserWithEmailAndPassword(email, password)
  .then(() => createSignUp({ firstName, lastName, email, companyName, ic, dic, tariffCountry: "CZ", tariffType, countUsers, isSubscriber }));

const updatePassword = newPassword => firebase.auth().currentUser.updatePassword(newPassword);

const reauthenticate = (email, password) => {
  const user = firebase.auth().currentUser;
  const credentials = firebase.auth.EmailAuthProvider.credential(
    email,
    password
  );
  return user.reauthenticateWithCredential(credentials);
};

export {
  AuthPolicy,
  signIn,
  signOut,
  signUp,
  updatePassword,
  reauthenticate,
  restoreSession,
  resetPassword,
  resendVerificationMail,
  FirebaseSessionExpiredError,
  SessionExpiredError,
  AuthError,
  reloadSession
};
