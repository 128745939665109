import React, { useEffect, useState } from "react";

import { Fade, makeStyles, Grid } from "@material-ui/core";
import queryString from "query-string";

import AuthActionForm from "./AuthActionForm";
import AuthResetPasswordForm from "./AuthResetPasswordForm";
import AuthSignInForm from "./AuthSignInForm";
import SignUpContainer from "../SignUp/SignUpContainer";
import AuthView from "./AuthView";
import useRouter from "../../hook/useRouter";
import AuthOneColumn from "./AuthOneColumn";
import AuthSignUp from "./AuthSignUp";

import { readPublicTariffs } from "../../../resource";
import AuthSetPasswordForm from "./AuthSetPasswordForm";

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  mainSign: {
    backgroundColor: "#eeeee",
    minHeight: "100%"
  },
  logo: {
    width: 250
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: spacing(3)
  },
  content: {
    padding: spacing(2),
    "& > section": {
      display: "flex",
      justifyContent: "space-between"
    }
  },
  // mainTwoCols: {
  //   display: "flex",
  //   flexDirection: "row",
  //   width: "auto",
  //   marginLeft: spacing(2),
  //   marginRight: spacing(2),
  //   paddingTop: spacing(10),
  //   paddingBottom: spacing(1),
  //   [breakpoints.up(360 + spacing(4))]: {
  //     width: 720,
  //     marginLeft: "auto",
  //     marginRight: "auto"
  //   },
  //   "& > div": {
  //     marginTop: spacing(4),
  //     display: "flex",
  //     paddingTop: spacing(2),
  //     paddingBottom: spacing(2),
  //     paddingLeft: spacing(3),
  //     paddingRight: spacing(3),
  //     "& button": {
  //       width: "100%"
  //     },
  //     "& > h1": {
  //       marginBottom: spacing(2)
  //     },
  //     "& > span": {
  //       textAlign: "center"
  //     },
  //     "& > form": {
  //       width: "100%",
  //       "& > section": {
  //         marginBottom: spacing(2),
  //         "& > div": {
  //           width: "100%",
  //           "&:not(last-child)": {
  //             marginBottom: spacing(1)
  //           }
  //         }
  //       }
  //     }
  //   }
  // },
  main: {
    display: "flex",
    flexDirection: "row",
    width: "auto",
    marginLeft: spacing(2),
    marginRight: spacing(2),
    paddingTop: spacing(10),
    paddingBottom: spacing(1),
    [breakpoints.up(420 + spacing(4))]: {
      width: 420,
      marginLeft: "auto",
      marginRight: "auto"
    },
    "& > div": {
      marginTop: spacing(4),
      display: "flex",
      paddingTop: spacing(2),
      paddingBottom: spacing(2),
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
      "& button": {
        width: "100%"
      },
      "& > h1": {
        marginBottom: spacing(2)
      },
      "& > span": {
        textAlign: "center"
      },
      "& > form": {
        width: "100%",
        "& > section": {
          marginBottom: spacing(2),
          "& > div": {
            width: "100%",
            "&:not(last-child)": {
              marginBottom: spacing(1)
            }
          }
        }
      }
    }
  }
}));

const Auth = ({ formType }) => {
  const { location: { search } } = useRouter();
  const { tariff, userCount } = queryString.parse(search);
  const [selectedTariff, setSelectedTariff] = useState();

  let form;

  switch (formType) {
    case AuthView.ACTION:
      form = (
        <AuthActionForm />
      );
      break;
    case AuthView.SIGN_UP:
      form = (
        <SignUpContainer />
      );
      break;
    case AuthView.RESET_PASSWORD:
      form = (
        <AuthResetPasswordForm />
      );
      break;
    case AuthView.SET_PASSWORD:
      form = (
        <AuthSetPasswordForm />
      );
      break;
    default:
      form = (
        <AuthSignInForm />
      );
      break;
  }
  const classes = useStyles();

  useEffect(() => {
    const fetchTariffs = async () => {
      const raw = await readPublicTariffs("CZ");
      const currentTariff = tariff ? raw.find(({ type }) => tariff.toUpperCase() === type) : undefined;
      setSelectedTariff(currentTariff);
    };
    fetchTariffs();
  }, []);

  return (
    <div className={classes.mainSign}>
      <main className={classes.main}>
        <Fade in timeout={1000}>
          {formType === AuthView.SIGN_UP
            ? <AuthSignUp
              classes={classes}
              form={
                <SignUpContainer
                  userCount={userCount ? Number(userCount) : undefined}
                  tariff={selectedTariff}
                />
              }
              tariff={selectedTariff}
              userCount={userCount}
            />
            : <AuthOneColumn classes={classes} form={form} />}
        </Fade>
      </main>
    </div>
  );
};

export default Auth;
