import React from "react";

import { Button, Grid, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { differenceInDays, differenceInHours } from "date-fns";

import Page from "../../common/Page";
import PersistentSnackbar from "../../common/PersistentSnackbar";
import OverviewNewsCard from "./OverviewNewsCard";

import { TariffType } from "../../enums/enums";

const useStyles = makeStyles(({ spacing, palette }) => ({
  iconMargin: {
    marginRight: spacing(2)
  },
  snackbarButton: {
    color: palette.common.white
  }
}));

const Overview = ({
  content,
  news,
  subscriptionExpirationDays,
  isTrialSubscription,
  showTariffSnackbar,
  showVerifiedEmailSnackbar,
  sendVerificationMail,
  setShowVerifiedEmailSnackbar,
  setShowTariffSnackbar,
  loadingNews
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Page title={t("menu.overview")}>
      <Grid container>
        {/*showVerifiedEmailSnackbar*/ false ? (
          <Grid item xs={12}>
            <PersistentSnackbar
              variant="warning"
              message={t("emailNotVerifiedMessage")}
              link={(
                <Button
                  key="emailButtonPersistentSnackbar"
                  className={classes.snackbarButton}
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={sendVerificationMail}
                >
                  {t("resendVerificationEmail")}
                </Button>
              )}
              handleClose={() => setShowVerifiedEmailSnackbar(false)}
            />
          </Grid>
        ) : null}
        {showTariffSnackbar ? (
          <Grid item xs={12}>
            <PersistentSnackbar
              variant="error"
              message={t(isTrialSubscription ? "subscription.expiration.trial" : "subscription.expiration.common", { days: subscriptionExpirationDays })}
              handleClose={() => setShowTariffSnackbar(false)}
            />
          </Grid>
        ) : null}
        {content}
      </Grid>
    </Page>
  );
};

export default Overview;
