import React from "react";

import {
  TextField, Button, Typography, makeStyles
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";

import { SetPasswordSchema } from "../../../utility/validationSchema";
import useAuth from "../../hook/useAuth";
import { setPassword } from "../../../resource";

const useStyles = makeStyles(({ spacing }) => ({
  bottomMargin: {
    marginBottom: spacing(1)
  }
}));

const AuthSetPasswordForm = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const handleSubmit = ({ password, passwordAgain }, { setSubmitting }) => {
    if (password !== passwordAgain) {
      alert('Hesla se nezhodují.');
      setSubmitting(false);
      return;
    }

    if (password.length < 8) {
      alert("Heslo musí mít alespoň 8 znaků!");
      setSubmitting(false);
      return;
    }

    setPassword(password, token).then(() => {
      window.location.replace('/');
    });
  };

  return (
    <Formik
      initialValues={{
        password: "",
        passwordAgain: "",
      }}
      onSubmit={handleSubmit}
      //validationSchema={SetPasswordSchema}
    >
      {({
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        values,
        errors,
        touched
      }) => (
        <>
          <form onSubmit={handleSubmit}>
            <div>
              <TextField
                name="password"
                fullWidth
                type={"password"}
                label={t("newPassword")}
                required
                value={values.password}
                error={errors.password && touched.password}
                helperText={touched.password && t(errors.password) || " "}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
            <div>
              <TextField
                name="passwordAgain"
                fullWidth
                type={"password"}
                label={t("reenterPassword")}
                required
                value={values.passwordAgain}
                error={errors.passwordAgain && touched.passwordAgain}
                helperText={touched.passwordAgain && t(errors.passwordAgain) || " "}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={isSubmitting}
              className={classes.bottomMargin}
            >
              {t("resetPassword")}
            </Button>
            <div>
              <Typography variant="caption">
                {t("newToCargotic")}
                &nbsp;
                <Link to="/sign-up">
                  {t("signUp")}
                  !
                </Link>
              </Typography>
              <Typography variant="caption" paragraph>
                {t("goBackTo")}
                &nbsp;
                <Link to="/sign-in">{t("signIn")}</Link>
              </Typography>
            </div>
          </form>
        </>
      )}
    </Formik>
  );
};

export default AuthSetPasswordForm;
